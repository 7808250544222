import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import globals from '@/globals'

// Layouts
import Layout1 from '@/layout/Layout1'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: () => import('@/components/Login')
    },
    {
    path: '/',
      component: Layout1,
      children: [{
        path: '/',
        component: () => import('@/components/Home')
      }, {
        path: '/cli/mtsmta',
        component: () => import('@/components/clientes/mtsmta')
      }, {
        path: '/cli/moldes',
        component: () => import('@/components/clientes/moldes')
      }, {
        path: '/cli/detallemtsmta/:codigo/:nit',
        component: () => import('@/components/clientes/detalleMTSMTA')
      }, {
        path: '/ventas/idia',
        component: () => import('@/components/indicadores/indicadoresDia')
      },{
        path: '/ventas/vplanta',
        component: () => import('@/components/indicadores/ventasPlanta')
      }, {
        path: '/ventas/cartera',
        component: () => import('@/components/indicadores/cartera')
      }, {
        path: '/ventas/cplantanta',
        component: () => import('@/components/indicadores/cargaPlanta')
      }, {
        path: '/ventas/casesor',
        component: () => import('@/components/indicadores/cargaAsesor')
      }, {
        path: '/ventas/fproject',
        component: () => import('@/components/indicadores/facturacionProyectada')
      }, {
        path: '/usr/users',
        component: () => import('@/components/usuarios/Users')
      },
      {
        // 404 Not Found page
        path: '*',
        component: () => import('@/components/Home')
      }
    ]
  }]
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
