<template>
 <sidenav orientation="horizontal" :showDropdownOnHover="true" class="bg-secondary text-light" style="position: relative;z-index: 99">
      <div class="sidenav-inner">
        <b-navbar-brand to="/">
          <img :src="require('@/assets/imgs/LogoCreatum.png')" class="d-none d-sm-block img-responsive mt-1 mb-0" width="110" alt="Responsive image" />
        </b-navbar-brand>
        <sidenav-menu v-if="access.includes('001')" icon="ion ion-md-people" :active="isMenuActive('/cli')">
          <template slot="link-text">Clientes</template>
          <sidenav-link href="/cli/mtsmta">MTS MTA</sidenav-link>
          <sidenav-link href="/cli/moldes">Moldes Inyección</sidenav-link>
        </sidenav-menu>
        <sidenav-menu icon="ion ion-md-people" :active="isMenuActive('/ventas')" v-if="access.includes('002')">
          <template slot="link-text">Ventas</template>
          <sidenav-link href="/ventas/idia">Indicadores Día</sidenav-link>
          <sidenav-link href="/ventas/vplanta">Ventas Planta</sidenav-link>
          <sidenav-link href="/ventas/cartera">Cartera</sidenav-link>
          <sidenav-link href="/ventas/cplantanta">Carga Plantas</sidenav-link>
          <sidenav-link href="/ventas/casesor">Carga Asesor</sidenav-link>
          <sidenav-link href="/ventas/fproject">Facturación Proyectada</sidenav-link>
        </sidenav-menu>
        <sidenav-menu icon="ion ion-md-contact" :active="isMenuActive('/usr')">
          <template slot="link-text">Usuarios</template>
          <sidenav-link v-if="access.includes('003')" href="/usr/users">Usuarios </sidenav-link>
          <b-dd-item @click="logout"><i class="ion ion-ios-log-out"></i> &nbsp; Log Out</b-dd-item>
        </sidenav-menu>
      </div>
    </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'
import axios from "axios";
import { create } from 'ladda';
export default {

  name: 'app-layout-navbar',
  components: {
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
  },
  data: () => ({

    access: "",
    sidenav1Collapsed: false,
    sidenav2Collapsed: false,
    sidenav3Collapsed: false,
    sidenav4Collapsed: false,
    sidenav5Collapsed: false,
    sidenav6Collapsed: false,
  }),
  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },
    logout: function(){
        localStorage.removeItem('plataforma.cloud')
        this.$router.push(this.$route.query.redirect || '/login')
    }
  },
     beforeCreate() {
         if(localStorage.getItem('plataforma.cloud') == null)
            this.$router.push(this.$route.query.redirect || '/login')
   },

   create:function(){

      axios.get(`https://usuarios.creatum.com.co/validate/Creatum/Plataforma`,{headers: {'Authorization': JSON.parse(localStorage.getItem('plataforma.cloud')).token}}
      )
      .then(response => {
        
        if(response.data.status === "Authorized")
        {
          // console.log(response)
        }else{
          localStorage.removeItem('plataforma.cloud')
          this.$router.push(this.$route.query.redirect || '/login')
        }

      })
      .catch(e => {
        
        localStorage.setItem('plataforma.cloud', JSON.stringify(response.data));
        this.$router.push(this.$route.query.redirect || '/login')
      });
   },
   beforeCreate() {
     if(JSON.parse(localStorage.getItem('plataforma.cloud')) != null){

      axios.get(`https://datosplataforma.creatum.com.co/obtenerOpciones/`+ JSON.parse(localStorage.getItem('plataforma.cloud')).idUser,
        {
          headers: {'Authorization': JSON.parse(localStorage.getItem('plataforma.cloud')).token}
        })
        .then(response => {
          if(response.data=="Error!"){
            localStorage.removeItem('plataforma.cloud')
            this.$router.push(this.$route.query.redirect || '/login')
          }else{
            // console.log(response.data)
          this.access = response.data
          }
        })
        .catch(e => {
        });

      }else{

            localStorage.removeItem('plataforma.cloud')
            this.$router.push(this.$route.query.redirect || '/login')
      }
  },
}
</script>
